import React from 'react';

const Welcome: React.FC = () => {
  return (
    <div className="p-8">
      <h1 className="text-center text-3xl font-bold mb-8">Welcome to MyApp</h1>

      <div className="mt-16">
        <h2 className="text-2xl font-semibold mb-4">More Content</h2>
        <p className="mb-2">This is some more scrollable content to demonstrate the sticky navbar.</p>
        <p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit...</p>
        <p className="mb-2">Vestibulum euismod, nisl eget consectetur consectetur...</p>
        <p className="mb-2">Scroll to see the sticky navbar in action.</p>
      </div>
    </div>
  );
};

export default Welcome;