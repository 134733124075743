'use client'
import NavigationBar from '@/components/ui/NavigationBar';
import OrganizationSelector from '@/components/ui/OrganizationSelector';
import Welcome from '@/components/ui/Welcome';
import { useAuth } from '@clerk/nextjs';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';

const Home = () => {
    const { isSignedIn, orgId, orgSlug} = useAuth();
    const router = useRouter();
    console.log('orgId:', orgId, orgSlug);

    useEffect(() => {
        if (isSignedIn && orgId && orgSlug) {
            router.push(`/${orgSlug}/dossiers`);
        }
    }, [isSignedIn, orgId, orgSlug, router]);


    return (
        <div>
            <NavigationBar />

            {isSignedIn && !orgId &&
                <OrganizationSelector />
            }

            {!isSignedIn &&
                <Welcome />
            }
        </div>
    );
};

export default Home;